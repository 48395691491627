import React from "react";
import PropTypes from "prop-types";
import Image from "../../Image";

const CustomArrow = ({
  sx,
  nextIcon,
  nextSx,
  prev,
  prevIcon,
  prevSx,
  onClick,
}) => {
  const prevStyle = {
    ...prevSx,
  };
  const nextStyle = {
    ...nextSx,
  };
  const customSx = {
    "&:after, &:before": {
      display: "none !important",
    },
    ":hover": {
      bg: "primaryDark",
      color: "white",
    },
    "> *": {
      display: "inline-block !important",
      height: "auto",
      verticalAlign: "middle",
      width: "100%",
    },
    bg: "transparent",
    color: "primary",
    p: 2,
    transform: "translateY(-50%)",
    width: "6rem",
    zIndex: "10",
    ...sx,
  };
  return (
    <Image
      className={`custom-arrow ${
        prev ? "custom-arrow-prev" : "custom-arrow-next"
      }`}
      onClick={onClick}
      svgIcon={prev ? prevIcon : nextIcon}
      sx={{ ...customSx, ...(prev ? prevStyle : nextStyle) }}
    />
  );
};

CustomArrow.propTypes = {
  nextIcon: PropTypes.string,
  nextSx: PropTypes.shape({}),
  onClick: PropTypes.func,
  prev: PropTypes.bool,
  prevIcon: PropTypes.string,
  prevSx: PropTypes.shape({}),
  sx: PropTypes.shape({}),
};

CustomArrow.defaultProps = {
  nextIcon: "arrow-right",
  nextSx: {},
  onClick: () => null,
  prev: false,
  prevIcon: "arrow-left",
  prevSx: {},
  sx: {},
};

export default CustomArrow;
