import PropTypes from "prop-types";
import React, { useRef, useEffect } from "react";
import Slider from "react-slick";
import Box from "../../base/Box";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Carousel = ({
  afterChange,
  children,
  onNextPrev,
  settings,
  startPlay,
  sx,
}) => {
  const sliderStyled = {
    "&.slick-slider": {
      "& .slick-next, & .slick-prev": {
        height: "auto",
      },
      "& .slick-slide img": {
        display: "unset",
      },
      "&, & .slick-list, & .slick-slide, & .slick-slide > div": {
        flexDirection: "column",
      },
      "&, & .slick-list, & .slick-track, & .slick-slide, & .slick-slide > div":
        {
          display: "flex",
          flex: "auto",
        },
      ...sx,
    },
  };

  const defaultSetting = {
    // dots: true,
    ...settings,
  };

  const sliderRef = useRef();

  useEffect(() => {
    if (onNextPrev !== null && sliderRef?.current) {
      sliderRef.current.slickGoTo(onNextPrev);
    }
  }, [onNextPrev]);

  useEffect(() => {
    if (startPlay !== null && sliderRef?.current) {
      if (startPlay === "play") {
        sliderRef.current.slickPlay();
      } else {
        sliderRef.current.slickPause();
      }
    }
  }, [startPlay]);

  return (
    <Box
      ref={sliderRef}
      as={Slider}
      sx={sliderStyled}
      {...defaultSetting}
      afterChange={(i) => afterChange(i)}
    >
      {children}
    </Box>
  );
};

Carousel.propTypes = {
  afterChange: PropTypes.func,
  children: PropTypes.node.isRequired,
  onNextPrev: PropTypes.number,
  settings: PropTypes.shape({}),
  startPlay: PropTypes.string,
  sx: PropTypes.shape({}),
};

Carousel.defaultProps = {
  afterChange: () => null,
  onNextPrev: null,
  settings: {},
  startPlay: null,
  sx: {},
};

export default Carousel;
