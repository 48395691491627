import PropTypes from "prop-types";
import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import "./displayFont.css";
import Section from "../Section";
import Heading from "../Heading";

const HeroBanner = ({
  bannerImage,
  containerSx,
  children,
  sx,
  title,
  titleSx,
  ...rest
}) => {
  const theme = useContext(ThemeContext);
  return (
    <Section
      bgImage={bannerImage}
      containerSx={{
        display: "flex",
        pt: [5, null, null, null, 6],
        ...containerSx,
      }}
      sx={{
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        flexDirection: "column",
        minHeight: "25rem",
        mt: [
          `-${theme.sizes.heights.header[0]}`,
          null,
          null,
          `-${theme.sizes.heights.header[1]}`,
        ],
        pb: 0,
        ...sx,
      }}
      {...rest}
    >
      {title && (
        <Heading
          sx={{
            color: "white",
            fontSize: 5,
            textAlign: "center",
            textShadow: 6,
            ...titleSx,
          }}
        >
          {title}
        </Heading>
      )}
      {children}
    </Section>
  );
};

HeroBanner.propTypes = {
  bannerImage: PropTypes.string,
  children: PropTypes.node,
  containerSx: PropTypes.shape({}),
  overlaySx: PropTypes.shape({}),
  sx: PropTypes.shape({}),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  titleSx: PropTypes.shape({}),
};

HeroBanner.defaultProps = {
  bannerImage: "",
  children: null,
  containerSx: {},
  overlaySx: {},
  sx: {},
  title: "",
  titleSx: {},
};

export default HeroBanner;
